// Vendor
import React from "react";
import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { CloudinaryContext } from "cloudinary-react";

// App
import "./styles/index.css";
import ApolloClientProvider from "./common/ApolloClientProvider";
import * as serviceWorker from "./serviceWorker";
import Layout from "./components/app/Layout";
import CloudinaryHelper from "./common/CloudinaryHelper";
import initFontawesomeLib from "./config/fontawesome";
import URLHelper from "./common/URLHelper";
import ErrorBoundary from "./components/ui/ErrorBoundary";

initFontawesomeLib();

Sentry.init({
  dsn: "https://281261cc4f5e466fa2f965c8f03162ed@sentry.io/1882473",
  environment: URLHelper.isLocalhost() ? "development" : "production"
});

ReactDOM.render(
  <ApolloProvider client={ApolloClientProvider.client}>
    <BrowserRouter>
      <CloudinaryContext
        cloudName="greenfee365"
        dpr="auto"
        defaultImage={CloudinaryHelper.FALLBACK_IMG}
        className="h-100"
      >
        <ErrorBoundary context="index" global showReloadBtn>
          <Layout />
        </ErrorBoundary>
      </CloudinaryContext>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
