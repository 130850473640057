// vendor
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useMutation } from "react-apollo";
import { Button } from "reactstrap";

// app
import SeasonList from "./seasons/SeasonList";
import ClubCourseGeneralInfo from "./categories/ClubCourseGeneralInfo";
import ClubCourseContactSelect from "./categories/ClubCourseContactSelect";
import ClubCourseAmenityList from "./categories/ClubCourseAmenityList";
import ClubCourseAreaList from "./categories/ClubCourseAreaList";
import ClubCourseLocation from "./categories/ClubCourseLocation";
import ClubCoursePresentation from "./categories/ClubCoursePresentation";
import { COURSE_UPDATE, COURSE_CREATE } from "../../../common/Mutations";
import ClubCourseImageList from "./categories/ClubCourseImageList";
import clubCourseReducer from "./clubCourseReducer";
import { useContext } from "react";
import { LayoutContext } from "../../app/Layout";
import ContactInfo from "../../ui/ContactInfo";
import { Alert } from "reactstrap";
import Loader from "../../ui/Loader";
import { ClubContext } from "../Club";
import Lazy from "../../ui/Lazy";
import ClubCourseTeeList from "./categories/ClubCourseTeeList";
import usePreventWindowUnload from "../../../hooks/usePreventWindowUnload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvForm from "availity-reactstrap-validation/lib/AvForm";

export const ClubCourseContext = React.createContext(null);

function ClubCourse(props) {
  const { course } = props;
  async function handleSave() {
    const { clubId } = props;
    const courseId = course._id;

    // copy state as mutation input
    let input = { ...state };

    // not part of schema
    delete input._id;
    delete input.seasonRates;

    // Create
    if (!courseId) createCourse({ variables: { input, clubId } });
    // Update
    else {
      // if user has selected the club's address, we need to empty the fields
      if (!hasOwnContactInfo)
        input.dailyOrderEmail = input.address = input.city = input.state = input.country = input.countryCode =
          "";

      updateCourse({ variables: { input, courseId } });
    }
  }

  function handleCreateCompleted(data) {
    const payload = data.createCourse.course;
    setEdited(false);
    addAlert({ color: "success", message: "Course was successfully created" });
    dispatch({ type: "INIT", payload });
    clubDispatch({ type: "COURSES_SAVE_NEW", payload });
  }

  function handleCreateError() {
    addAlert({ color: "danger", message: "Could not create course" });
  }

  function handleUpdateCompleted(data) {
    setEdited(false);
    addAlert({ color: "success", message: "Course was successfully saved" });
    dispatch({ type: "INIT", payload: data.updateCourse.course });
  }

  function handleUpdateError() {
    addAlert({ color: "danger", message: "Could not update course" });
  }

  function middleware(event, trigger = true) {
    if (!edited && trigger) setEdited(true);
    dispatch(event);
  }

  // hooks
  const [edited, setEdited] = useState(false);
  const [description, setDescription] = useState("");
  const [i18n, setI18n] = useState(undefined);
  const [hasOwnContactInfo, setHasOwnContactInfo] = useState(false);
  usePreventWindowUnload(edited);

  const [state, dispatch] = React.useReducer(clubCourseReducer, null);

  const { addAlert } = useContext(LayoutContext);
  const { dispatch: clubDispatch } = useContext(ClubContext);

  const [createCourse, { loading: createLoading }] = useMutation(
    COURSE_CREATE,
    {
      onCompleted: handleCreateCompleted,
      onError: handleCreateError
    }
  );

  const [updateCourse, { loading: updateLoading }] = useMutation(
    COURSE_UPDATE,
    {
      onCompleted: handleUpdateCompleted,
      onError: handleUpdateError
    }
  );

  // update when 'course' prop change
  useEffect(() => {
    // check if course has its own contact info
    const { address, city, state, country, countryCode } = course;
    const fields = [address, city, state, country, countryCode];
    if (fields.some(field => field && field !== "")) setHasOwnContactInfo(true);

    setDescription(course.description);
    setI18n(course.i18n);
    dispatch({ type: "INIT", payload: course });
  }, [course]);

  // guard
  if (!state) return null;

  return (
    <ClubCourseContext.Provider value={{ state, dispatch: middleware }}>
      {(createLoading || updateLoading) && <Loader fullscreen />}
      <a
        className="float-right"
        href={`https://greenfee365.com/golf-clubs/${props.course.slug}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon="external-link-alt" className="mr-1" />
        Profile on Greenfee365.com
      </a>
      <AvForm onValidSubmit={handleSave}>
        <ClubCourseGeneralInfo />
        <Lazy>
          <ClubCoursePresentation
            onChange={event => {
              const type = event.key === "description" ? "DESCRIPTION" : "I18N";
              middleware({ type, payload: event.value });
            }}
            description={state.description || description}
            descriptionOld={description}
            i18n={state.i18n || i18n}
            i18nOld={i18n}
          />
        </Lazy>
        <Lazy>
          <ClubCourseImageList slug={state.slug} />
        </Lazy>

        <ClubCourseTeeList />

        <ClubCourseContactSelect
          onChange={value => {
            setEdited(true);
            setHasOwnContactInfo(value);
          }}
          hasOwnContactInfo={hasOwnContactInfo}
        />
        {hasOwnContactInfo && <ContactInfo context={ClubCourseContext} />}
        <Lazy>
          <ClubCourseLocation />
        </Lazy>
        <ClubCourseAreaList />
        <ClubCourseAmenityList />

        {state._id ? (
          <SeasonList seasonRates={state.seasonRates} />
        ) : (
          <div className="mt-5">
            <h3>Seasons</h3>
            <Alert color="warning">
              You must save your course before you can add seasons.
            </Alert>
          </div>
        )}

        <Button
          size="lg"
          type="submit"
          className="mt-4 float-right"
          color={edited ? "secondary" : "gray"}
          disabled={!edited}
          // onClick={handleSave}
        >
          Save
        </Button>
      </AvForm>
    </ClubCourseContext.Provider>
  );
}

ClubCourse.propTypes = {
  clubId: PropTypes.string.isRequired,
  course: PropTypes.shape({
    _id: PropTypes.string,
    active: PropTypes.bool,
    address: PropTypes.string, // Optional override club level
    amenities: PropTypes.arrayOf(PropTypes.string),
    architect: PropTypes.string,
    areas: PropTypes.arrayOf(PropTypes.string),
    cancellationHours: PropTypes.number,
    claimed: PropTypes.bool, //.isRequired,
    claimedDate: PropTypes.string,
    city: PropTypes.string, // Optional override club level
    state: PropTypes.string, // Optional override club level
    country: PropTypes.string, // Optional override club level
    countryCode: PropTypes.string, // Optional override club level
    courseType: PropTypes.string,
    currency: PropTypes.string,
    description: PropTypes.string,
    guestPolicy: PropTypes.string,
    holes: PropTypes.number,
    i18n: PropTypes.object,
    linkedClubs: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        name: PropTypes.string
      })
    ),
    loc: PropTypes.shape({
      coordinates: PropTypes.arrayOf(PropTypes.number)
    }),
    localTimeZone: PropTypes.string,
    membership: PropTypes.string,
    name: PropTypes.string,
    par: PropTypes.number,
    placeId: PropTypes.string,
    seasonRates: PropTypes.arrayOf(
      PropTypes.shape({
        //_id: PropTypes.string,
        seasonalType: PropTypes.string,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        prices: PropTypes.arrayOf(
          PropTypes.shape({
            //_id: PropTypes.string,
            dayType: PropTypes.string,
            price: PropTypes.number,
            rackPrice: PropTypes.number,
            rateType: PropTypes.string,
            scheduleType: PropTypes.string,
            targetRateId: PropTypes.arrayOf(PropTypes.string)
          })
        ),
        buggyIncluded: PropTypes.bool
      })
    ),
    slug: PropTypes.string
  }).isRequired
};

export default ClubCourse;
