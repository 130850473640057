// vendor
import React, { useContext } from "react";
import { useQuery } from "react-apollo";

// app
import { ACCOUNT_MANAGERS } from "../../../common/Queries";
import Loader from "../../ui/Loader";
import { Select } from "../../ui/FunctionalComponents";
import variables from "../../../styles/bootstrap/_variables.scss";
import { ClubContext } from "../Club";

function ClubAccountManagerSelect() {
  function getAccountManagerName(accountManager) {
    return `${accountManager.firstName} ${accountManager.lastName}`;
  }

  function getAccountManager(id) {
    const managers = data.accountManagers;
    if (!managers) return undefined;
    return managers.find(manager => manager._id === id);
  }

  const { state, dispatch } = useContext(ClubContext);
  const { loading, error, data } = useQuery(ACCOUNT_MANAGERS);

  if (loading) return <Loader />;
  if (error)
    return <p className="text-danger">Could not load country managers.</p>;

  let manager = null;
  if (state.accountManager)
    manager = getAccountManager(state.accountManager._id);

  return (
    <Select
      label="Account manager"
      id="club-account-manager"
      value={manager && manager._id}
      onChange={e => {
        const manager = getAccountManager(e.target.value);
        dispatch({ type: "ACCOUNT_MANAGER", payload: manager });
      }}
      style={manager === null ? { color: variables.gray400 } : null}
    >
      {!manager && (
        <option disabled value={false}>
          Select manager
        </option>
      )}
      {data.accountManagers.map((am, i) => (
        <option value={am._id} key={i}>
          {getAccountManagerName(am)}
        </option>
      ))}
    </Select>
  );
}

export default ClubAccountManagerSelect;
