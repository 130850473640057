// Vendor
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Table, Button, Badge } from "reactstrap";
import moment from "moment";
import SVG from "react-inlinesvg";

import RatesHelper from "../../../../common/RatesHelper";
import rateIconEarlyBird from "../../../../images/rates/rate-early_bird.svg";
import rateIconStandard from "../../../../images/rates/rate-standard.svg";
import rateIconTwilight from "../../../../images/rates/rate-twilight.svg";
import SeasonTypeCollection from "./collections/SeasonTypeCollection";
import Season, { SeasonPropTypes } from "./Season";
import {
  getSeasonStartDateFormat,
  getSeasonEndDateFormat
} from "./SeasonHelper";

function ClubCourseSeasonList(props) {
  const seasons = props.seasonRates;

  const [selectedSeason, setSeason] = useState(null);
  const [seasonOpen, setOpen] = useState(false);

  function getIconClass(active) {
    const baseClass = "icon";
    let className = `${baseClass} h-100 w-100 `;
    className += active ? `${baseClass}--active` : `${baseClass}--inactive`;
    return className;
  }

  function getIcons(rates, scheduleType) {
    const hasEarlyBird =
      RatesHelper.filter(rates, {
        scheduleType,
        dayType: "EARLY_BIRD"
      }).length > 0;
    const hasStandard =
      RatesHelper.filter(rates, {
        scheduleType,
        dayType: "STANDARD"
      }).length > 0;
    const hasTwilight =
      RatesHelper.filter(rates, {
        scheduleType,
        dayType: "TWILIGHT"
      }).length > 0;

    return (
      <>
        <div className="icon-container">
          <SVG className={getIconClass(hasEarlyBird)} src={rateIconEarlyBird} />
        </div>
        <div className="icon-container">
          <SVG className={getIconClass(hasStandard)} src={rateIconStandard} />
        </div>
        <div className="icon-container">
          <SVG className={getIconClass(hasTwilight)} src={rateIconTwilight} />
        </div>
      </>
    );
  }

  function getBadge(type) {
    let season = SeasonTypeCollection.find(c => c.type === type);
    return <Badge color={season.color}>{season.name}</Badge>;
  }

  return (
    <div className="ClubCourseSeasonList mt-5">
      <h3>Seasons</h3>

      <Table responsive className="border-0">
        <thead>
          <tr>
            <th colSpan="3">&nbsp;</th>
            <th colSpan="2" className="text-center">
              Standard
            </th>
            <th colSpan="2" className="text-center">
              Weekend
            </th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th>Type</th>
            <th className="text-left">From</th>
            <th className="text-left">To</th>
            <th>Rates</th>
            <th>Day types</th>
            <th>Rates</th>
            <th>Day types</th>
            {/* <th>&nbsp;</th> */}
          </tr>
        </thead>
        <tbody className="border">
          {!seasons.length && (
            <tr>
              <td colSpan="8" className="text-muted text-center">
                No seasons defined for this course
              </td>
            </tr>
          )}
          {seasons.length > 0 &&
            seasons.map((season, i) => (
              <tr key={i}>
                <td> {getBadge(season.seasonalType)} </td>
                <td className="text-left" style={{ whiteSpace: "nowrap" }}>
                  <small>{moment(season.startDate).format("YYYY-MM-DD")}</small>
                </td>
                <td className="text-left" style={{ whiteSpace: "nowrap" }}>
                  <small>{moment(season.endDate).format("YYYY-MM-DD")}</small>
                </td>
                <td>
                  {
                    RatesHelper.filter(season.rates, {
                      scheduleType: "STANDARD"
                    }).length
                  }
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {getIcons(season.rates, "STANDARD")}
                </td>
                <td>
                  {
                    RatesHelper.filter(season.rates, {
                      scheduleType: "WEEKEND"
                    }).length
                  }
                </td>
                <td style={{ whiteSpace: "nowrap" }}>
                  {getIcons(season.rates, "WEEKEND")}
                </td>
                <td>
                  <Button
                    className="p-0"
                    color="link"
                    onClick={() => {
                      setSeason(season);
                      setOpen(true);
                    }}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Button
        color="link"
        onClick={() => {
          const newSeason = {
            startDate: getSeasonStartDateFormat(new Date()),
            endDate: getSeasonEndDateFormat(new Date()),
            seasonalType: "MIDDLE_SEASON",
            rates: []
          };
          setSeason(newSeason);
          setOpen(true);
        }}
      >
        + Add season
      </Button>
      <Season
        open={seasonOpen}
        season={selectedSeason}
        onDuplicate={() => {
          let clone = JSON.parse(JSON.stringify(selectedSeason));
          delete clone._id;

          setOpen(false);

          setTimeout(() => {
            setSeason(clone);
            setOpen(true);
          }, 1000);
        }}
        onClose={() => {
          setOpen(false);
          setSeason(null);
        }}
      />
    </div>
  );
}

ClubCourseSeasonList.propTypes = {
  seasonRates: PropTypes.arrayOf(PropTypes.shape(SeasonPropTypes))
};
ClubCourseSeasonList.defaultProps = {
  seasonRates: []
};

export default ClubCourseSeasonList;
