// Vendor
import { useQuery } from "@apollo/react-hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { Alert, Table } from "reactstrap";

// App
import Loader from "./Loader";

function SelectTable(props) {
  function addItem(club) {
    const { listItems, onChange } = props;
    if (listItems.some(c => c._id === club._id)) return;
    onChange([...listItems, club]);
  }

  function reorderIndex(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0]);
  }

  function moveItemUp(item) {
    const { listItems, onChange } = props;
    let curIndex = listItems.findIndex(c => c._id === item._id);

    // item not found
    if (curIndex === -1) return;

    // Cannot move up from top
    if (curIndex === 0) return;

    let reordered = [...listItems];
    reorderIndex(reordered, curIndex, curIndex - 1);

    onChange(reordered);
  }

  function moveItemDown(item) {
    const { listItems, onChange } = props;
    let curIndex = listItems.findIndex(c => c._id === item._id);

    // not found
    if (curIndex === -1) return;

    // Cannot move down from bottom
    if (curIndex === listItems.length - 1) return;

    let reordered = [...listItems];
    reorderIndex(reordered, curIndex, curIndex + 1);

    onChange(reordered);
  }

  function removeItem(item) {
    const { onChange, listItems } = props;
    onChange(listItems.filter(c => c._id !== item._id));
  }

  const Row = ({ item, index }) => {
    const first = index === 0;
    const last = props.listItems.length - 1 === index;
    const single = props.listItems.length === 1;

    return (
      <tr>
        <td style={{ width: "1px", whiteSpace: "nowrap" }}>
          <FontAwesomeIcon
            icon="arrow-down"
            onClick={() => !last && !single && moveItemDown(item)}
            className={`pointer d-inline-flex mr-2 ${
              last || single ? "text-muted" : "text-body"
            }`}
          />
          <FontAwesomeIcon
            icon="arrow-up"
            onClick={() => !first && !single && moveItemUp(item)}
            className={`pointer d-inline-flex ${
              first || single ? "text-muted" : "text-body"
            }`}
          />
        </td>
        <td className="text-left">{item.name}</td>
        <td className="text-right">
          <FontAwesomeIcon
            className="text-muted pointer"
            icon="trash-alt"
            onClick={() => removeItem(item)}
          />
        </td>
      </tr>
    );
  };

  const Select = ({ data, error, loading }) => {
    if (error) return <Alert color="danger">Failed to load</Alert>;
    if (loading) return <Loader />;

    // first field contains the data
    const field = Object.keys(data)[0];

    // sort options by name
    const selectableItems = data[field].sort((a, b) =>
      a.name > b.name ? 1 : -1
    );

    return (
      <select
        className="form-control form-control-lg mb-3"
        value="default"
        onChange={e => addItem(selectableItems[e.target.value])}
      >
        <option value="default" disabled>
          Select item
        </option>
        {selectableItems.map((item, i) => (
          <option value={i} key={i}>
            {item.name}
          </option>
        ))}
      </select>
    );
  };

  const {
    listItems,
    listContainerClass,
    selectContainerClass,
    selectQuery,
    selectQueryOptions,
    title
  } = props;

  const { error, loading, data } = useQuery(selectQuery, selectQueryOptions);

  return (
    <div className="mb-5">
      <h3 className="mb-3">{title}</h3>
      <div className="row align-items-center">
        <div className={selectContainerClass}>
          <Select error={error} loading={loading} data={data} />
        </div>
        <div className={listContainerClass}>
          <Table striped borderless>
            {listItems && listItems.length > 0 ? (
              <tbody className="border">
                {listItems.map((item, i) => (
                  <Row index={i} item={item} key={i} />
                ))}
              </tbody>
            ) : (
              <tbody className="border">
                <tr>
                  <td className="text-muted text-center">No items added</td>
                </tr>
              </tbody>
            )}
          </Table>
        </div>
      </div>
    </div>
  );
}

SelectTable.propTypes = {
  selectContainerClass: PropTypes.string,
  listContainerClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  showDescriptionCount: PropTypes.bool,
  selectQuery: PropTypes.object.isRequired, // object with gql string
  selectQueryOptions: PropTypes.object,
  title: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      i18n: PropTypes.object
    })
  )
};
SelectTable.defaultProps = {
  listItems: [],
  selectContainerClass: "col-12 col-md-6",
  listContainerClass: "col-12 col-md-6",
  showDescriptionCount: true,
  title: "",
  selectQueryOptions: {}
};

export default SelectTable;
