// Vendor
import React, { useState, useEffect } from "react";
import { Input, Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import * as moment from "moment";

// App
import useDebounce from "../../common/useDebounce";
import URLHelper from "../../common/URLHelper";
import DateRangeFacet from "../ui/facet/DateRangeFacet";

function OrderSearch() {
  const history = useHistory();
  const urlParams = URLHelper.queryToState(history.location);

  const [userSearchText, setUserSearchText] = useState(urlParams.userText);
  const [orderSearchText, setOrderSearchText] = useState(urlParams.orderText);
  const [clubName, setClubName] = useState(urlParams.clubName);
  const [createdDate, setCreatedDate] = useState({
    startDate: urlParams.createdFrom ? moment(urlParams.createdFrom) : null,
    endDate: urlParams.createdTo ? moment(urlParams.createdTo) : null
  });
  const [playDate, setPlayDate] = useState({
    startDate: urlParams.teetimeFrom ? moment(urlParams.teetimeFrom) : null,
    endDate: urlParams.teetimeTo ? moment(urlParams.teetimeTo) : null
  });

  const debouncedUserSearchText = useDebounce(userSearchText, 750);
  const debouncedOrderSearchText = useDebounce(orderSearchText, 750);
  const debouncedClubName = useDebounce(clubName, 750);
  const debouncedCreatedDate = useDebounce(createdDate, 750);
  const debouncedPlayDate = useDebounce(playDate, 750);

  /**
   * Update query params on any param change (debounced)
   */
  useEffect(() => {
    let arr = [
      { key: "userText", value: debouncedUserSearchText },
      { key: "orderText", value: debouncedOrderSearchText },
      { key: "clubName", value: debouncedClubName },
      ...formatDateQuery("created", debouncedCreatedDate),
      ...formatDateQuery("teetime", debouncedPlayDate)
    ];
    updateUrlQuery(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedClubName,
    debouncedOrderSearchText,
    debouncedUserSearchText,
    debouncedCreatedDate,
    debouncedPlayDate
  ]);

  function formatDateQuery(baseKey, { startDate, endDate }) {
    // Split datespan into separate query params
    let arr = [
      { key: `${baseKey}From`, value: null },
      { key: `${baseKey}To`, value: null }
    ];
    if (startDate && endDate) {
      arr[0].value = startDate.format("YYYY-MM-DD");
      arr[1].value = endDate.format("YYYY-MM-DD");
    }
    return arr;
  }

  /**
   * Update url query params
   * @param { Array<{key: string, value: string}> } arr Array of params in key/value pairs
   */
  function updateUrlQuery(arr) {
    for (let keyVal of arr) {
      if (keyVal.value) {
        urlParams[keyVal.key] = keyVal.value;
      } else {
        delete urlParams[keyVal.key];
      }
    }
    history.push({ search: URLHelper.queryFromState(urlParams) });
  }

  // Constant function to allow past dates in the daterange pickers
  const falseFunc = () => false;

  return (
    <div>
      <Row className="mb-3">
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-customer-info">User information</label>
          <Input
            id="input-customer-info"
            className="mb-2"
            type="text"
            placeholder="Name, email"
            onChange={e => setUserSearchText(e.target.value)}
            defaultValue={urlParams.userText}
          />
        </Col>
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-club-course-name">Club/course name</label>
          <Input
            id="input-club-course-name"
            className="mb-2"
            type="text"
            placeholder="Club/course"
            onChange={e => setClubName(e.target.value)}
            defaultValue={urlParams.clubName}
          />
        </Col>
        <Col className="col-12 col-lg-2 mb-2">
          <label htmlFor="input-order">Order</label>
          <Input
            id="input-order"
            className="mb-2"
            type="text"
            placeholder="Order ref, objectID"
            onChange={e => setOrderSearchText(e.target.value)}
            defaultValue={urlParams.orderText}
          />
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <label>Created date</label>
          <div className="DateRangePicker__custom-style">
            <DateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={createdDate.startDate}
              startDateId="orderCreatedFrom"
              endDate={createdDate.endDate}
              endDateId="orderCreatedTo"
              onDatesChange={({ startDate, endDate }) =>
                setCreatedDate({ startDate, endDate })
              }
              showClearDates
              isOutsideRange={falseFunc}
            />
          </div>
        </Col>
        <Col className="col-12 col-md-6 col-lg-3 mb-2">
          <label>Play date</label>
          <div className="DateRangePicker__custom-style">
            <DateRangeFacet
              small
              startDatePlaceholderText="From"
              endDatePlaceholderText="To"
              startDate={playDate.startDate}
              startDateId="playDateFrom"
              endDate={playDate.endDate}
              endDateId="playDateTo"
              onDatesChange={({ startDate, endDate }) =>
                setPlayDate({ startDate, endDate })
              }
              showClearDates
              isOutsideRange={falseFunc}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderSearch;
