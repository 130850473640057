// Vendor
import React, { useContext } from "react";
import { Row, Col } from "reactstrap";
import Switch from "react-switch";

// App
import {
  CLUBNAME_VALIDATOR,
  SLUG_VALIDATOR
} from "../../../../common/Validators";
import { InputField } from "../../../ui/FunctionalComponents";
import variables from "../../../../styles/bootstrap/_variables.scss";
import { ButtonGroup } from "reactstrap";
import classnames from "classnames";
import MembershipCollection from "../collections/MembershipCollection";
import { Select } from "../../../ui/FunctionalComponents";
import Timezones from "../../collections/TimezoneCollection";
import CourseTypes from "../../collections/CourseTypeCollection";
import { ClubCourseContext } from "../ClubCourse";
import CurrencySelect from "../../../ui/CurrencySelect";

function ClubCourseGeneralInfo(props) {
  function setCurrency(event) {
    dispatch({ type: "CURRENCY", payload: event });
  }
  const { state, dispatch } = useContext(ClubCourseContext);
  const {
    architect,
    active,
    cancellationHours,
    holes,
    membership,
    courseType,
    name,
    par,
    slug,
    localTimeZone
  } = state;

  const HolesBtn = ({ value }) => {
    const active = holes === value;
    const className = classnames({
      btn: true,
      "btn-lg": true,
      "btn-primary": active,
      "btn-outline-light": !active,
      "px-3": true
    });

    return (
      <button
        className={className}
        type="button"
        onClick={() => dispatch({ type: "HOLES", payload: value })}
      >
        {value}
      </button>
    );
  };

  return (
    <div className="ClubCourseGeneralInfo mt-3">
      <h3>General</h3>
      <Row form className="d-flex align-items-end">
        <Col md={4}>
          <InputField
            targetKey="name"
            label="Name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={event =>
              dispatch({ type: "COURSE_NAME", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <InputField
            targetKey="slug"
            label="Slug"
            value={slug}
            validate={SLUG_VALIDATOR}
            onChange={event => dispatch({ type: "SLUG", payload: event.value })}
          />
        </Col>
        <Col md={4}>
          <Select
            label="Membership"
            id={`${slug}-membership`}
            value={membership}
            onChange={e =>
              dispatch({ type: "MEMBERSHIP", payload: e.target.value })
            }
          >
            {MembershipCollection.items.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <InputField
            targetKey="architect"
            label="Architect"
            value={architect}
            onChange={event =>
              dispatch({ type: "ARCHITECT", payload: event.value })
            }
          />
        </Col>
        <Col md={4}>
          <Select
            label="Course type"
            id={`${slug}-coursetype`}
            value={courseType}
            onChange={e =>
              dispatch({ type: "COURSE_TYPE", payload: e.target.value })
            }
          >
            {CourseTypes.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col md={4}>
          <Select
            label="Time zone"
            id={`${slug}-timezone`}
            value={localTimeZone}
            onChange={e =>
              dispatch({ type: "LOCAL_TIME_ZONE", payload: e.target.value })
            }
          >
            {Timezones.map((item, i) => (
              <option value={item} key={i}>
                {item}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={6} md={2}>
          <CurrencySelect
            value={state.currency}
            onChange={value => setCurrency(value)}
          />
        </Col>
        <Col xs={6} md={4}>
          <InputField
            targetKey="cancellationHours"
            label="Cancellation"
            value={cancellationHours}
            type="number"
            required
            onChange={event =>
              dispatch({ type: "CANCELLATION_HOURS", payload: event.value })
            }
          />
        </Col>
        <Col xs={6} md={2}>
          <InputField
            targetKey="par"
            label="Par"
            value={par}
            type="number"
            required
            onChange={event => dispatch({ type: "PAR", payload: event.value })}
          />
        </Col>
        <Col xs={6} md={4}>
          <label>Holes</label>
          <ButtonGroup className="form-group">
            <HolesBtn value={9} />
            <HolesBtn value={18} />
            <HolesBtn value={27} />
          </ButtonGroup>
        </Col>
      </Row>

      <div className="switch-group mt-2 mr-3">
        <Switch
          id="switch-active"
          checked={active}
          onChange={() => dispatch({ type: "ACTIVE", payload: !active })}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor={variables.primary}
          offColor={variables.gray300}
          width={50}
        />
        <label
          htmlFor="switch-active"
          className={`${active ? "" : "text-muted"}`}
        >
          {active ? "Activated" : "Not activated"}
        </label>
      </div>
    </div>
  );
}

export default ClubCourseGeneralInfo;
