import React from "react";
import { useState } from "react";
import { SeasonScheduleContext } from "../../SeasonSchedule";

export function RateNumberEdit({
  id,
  name,
  value: initialValue,
  validateValue,
  onChange
}) {
  function invalidate() {
    addInvalidField(id);
    setInvalid(true);
  }

  function validate() {
    removeInvalidField(id);
    setInvalid(false);
  }

  // Use the context from Season.js
  const { addInvalidField, removeInvalidField } = React.useContext(
    SeasonScheduleContext
  );
  const [value, setValue] = useState(initialValue);
  const [invalid, setInvalid] = useState(false);

  React.useEffect(() => {
    // update from initial value if not same
    if (initialValue !== value) setValue(initialValue);
  }, [initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const hasValidateValue = validateValue && !isNaN(validateValue);

    // invalidate if dependency field has value but this doesn't (and if not already invalid)
    if (hasValidateValue && !value && !invalid) invalidate();
    // validate if has value and not already valid
    else if (value && invalid) validate();
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    const hasValidateValue = validateValue && !isNaN(validateValue);

    // invalidate if dependency field has value but this doesn't (and if not already invalid)
    if (hasValidateValue && !value && !invalid) invalidate();
    // validate if dependency field doesn't have value and is invalid
    else if (!hasValidateValue && invalid) validate();
  }, [validateValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <input
        className={`form-control form-control-sm ${
          invalid ? "is-invalid" : ""
        }`}
        type="number"
        name={name}
        min={0}
        value={value || ""}
        onKeyPress={event => {
          const { value } = event.target;
          if (event.charCode === 13) {
            // don't submit form if field is missing value but validate value is set
            if (value === "" && validateValue && !isNaN(validateValue)) return;

            onChange(value);
          }
        }}
        onChange={event => {
          const { value } = event.target;
          setValue(value);
          onChange(value);
        }}
      />
      {invalid && (
        <span className="invalid-feedback">This field is required</span>
      )}
    </>
  );
}

export function RateNumberRead({ ratePrice, field }) {
  let value = (ratePrice && ratePrice[field]) || undefined;
  return value ? value : <span className="text-muted">-</span>;
}
