// Vendor
import React, { useContext, useState, useEffect } from "react";
import { InputField, LanguageDropdown } from "../../ui/FunctionalComponents";
import { GolfDestinationContext } from "../GolfDestination";
import Switch from "react-switch";

// App
import CurrencySelect from "../../ui/CurrencySelect";
import ImageIdCard from "../../ui/ImageIdCard";
import FlagIcon from "../../ui/FlagIcon";
import {
  SLUG_VALIDATOR,
  CLUBNAME_VALIDATOR,
  NUM_DECIMAL_VALIDATOR_REQ,
  INT_VALIDATOR
} from "../../../common/Validators";
import CountryCollection from "../../../common/CountryCollection";
import variables from "../../../styles/bootstrap/_variables.scss";

function GeneralInfo() {
  const { state, dispatch } = useContext(GolfDestinationContext);
  const {
    imgId,
    name,
    slug,
    currency,
    country,
    coordinates,
    radius,
    zoom,
    published
  } = state;

  // State hooks
  const [countryOpen, setCountryOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(undefined);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    let match = CountryCollection.find(c => c.name === country);
    if (match) setSelectedCountry(getCountryItem(match));
    setCountries(CountryCollection.map(c => getCountryItem(c)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function setCurrency(event) {
    dispatch({ type: "CURRENCY", payload: event });
  }

  function handleCountryChange(item) {
    setSelectedCountry(item);
    dispatch({ type: "COUNTRY", payload: item.name });
    dispatch({ type: "COUNTRY_CODE", payload: item.code });
  }

  function getCountryItem(country) {
    return {
      name: country.name,
      code: country.code,
      jsx: (
        <>
          <FlagIcon code={country.code} className="mr-1" />
          <span>{country.name}</span>
        </>
      )
    };
  }

  function toggleCountryOpen() {
    setCountryOpen(!countryOpen);
  }

  return (
    <div className="mt-4 mb-5">
      <h3>General Information</h3>
      <div className="row mt-3">
        <div className="col-md mb-3">
          <ImageIdCard
            publicId={imgId}
            onChange={event =>
              dispatch({
                type: "IMAGE_ID",
                payload: event.value
              })
            }
            onChangeTargetKey="imgId"
          />
        </div>
        <div className="col-md">
          <InputField
            targetKey="name"
            label="Name"
            value={name}
            validate={CLUBNAME_VALIDATOR}
            onChange={event => dispatch({ type: "NAME", payload: event.value })}
          />
          <InputField
            targetKey="slug"
            label="Slug"
            value={slug}
            validate={SLUG_VALIDATOR}
            onChange={event => dispatch({ type: "SLUG", payload: event.value })}
          />

          <div className="row">
            <div className="col">
              <LanguageDropdown
                text={country}
                label="Country"
                items={countries}
                selectedItem={selectedCountry}
                onChange={handleCountryChange}
                id="destination-country"
                isOpen={countryOpen}
                toggle={toggleCountryOpen}
              />
            </div>
            <div className="col">
              <CurrencySelect
                value={currency}
                onChange={value => setCurrency(value)}
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <InputField
                targetKey="latitude"
                value={coordinates.latitude}
                label="Latitude"
                onChange={event =>
                  dispatch({
                    type: "COORDINATES",
                    payload: { key: "latitude", value: event.value }
                  })
                }
                validate={NUM_DECIMAL_VALIDATOR_REQ}
                options={{ required: true, type: "number" }}
              />
            </div>
            <div className="col">
              <InputField
                targetKey="longitude"
                value={coordinates.longitude}
                label="Longitude"
                onChange={event =>
                  dispatch({
                    type: "COORDINATES",
                    payload: { key: "longitude", value: event.value }
                  })
                }
                validate={NUM_DECIMAL_VALIDATOR_REQ}
                options={{ required: true, type: "number" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <InputField
                targetKey="radius"
                value={radius}
                label="Radius (km)"
                onChange={event =>
                  dispatch({ type: "RADIUS", payload: event.value })
                }
                validate={NUM_DECIMAL_VALIDATOR_REQ}
                options={{ required: true, type: "number" }}
              />
            </div>
            <div className="col">
              <InputField
                targetKey="zoom"
                value={zoom}
                label="Zoom level (0-19)"
                onChange={event =>
                  dispatch({ type: "ZOOM", payload: event.value })
                }
                validate={INT_VALIDATOR}
                options={{ required: true, type: "number" }}
              />
            </div>
          </div>

          <div className="switch-group">
            <Switch
              id="switch-published"
              checked={published}
              onChange={() =>
                dispatch({ type: "PUBLISHED", payload: !published })
              }
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={variables.primary}
              offColor={variables.gray300}
              width={50}
            />
            <label
              htmlFor="switch-published"
              className={`${published ? "" : "text-muted"}`}
            >
              {published ? "Published" : "Hidden"}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralInfo;

// // Vendor
// import React, { PureComponent } from "react";
// import PropTypes from "prop-types";
// import {
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem
// } from "reactstrap";

// // Common
// import CountryCollection from "../../../common/CountryCollection";
// import {
//   SLUG_VALIDATOR,
//   CLUBNAME_VALIDATOR,
//   NUM_DECIMAL_VALIDATOR_REQ,
//   INT_VALIDATOR
// } from "../../../common/Validators";
// import debounce from "../../../common/debounce";

// // App
// import { InputField } from "../../ui/FunctionalComponents";
// import ImageIdCard from "../../ui/ImageIdCard";
// import FlagIcon from "../../ui/FlagIcon";
// import CurrencySelect from "../../ui/CurrencySelect";

// class GeneralInfo extends PureComponent {
//   static propTypes = {
//     onChange: PropTypes.func.isRequired,
//     name: PropTypes.string,
//     slug: PropTypes.string,
//     currency: PropTypes.string,
//     country: PropTypes.string,
//     countryCode: PropTypes.string,
//     imgId: PropTypes.string,
//     coordinates: PropTypes.shape({
//       latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//       longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
//     }),
//     radius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
//     zoom: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
//   };
//   static defaultProps = {
//     name: "",
//     slug: "",
//     currency: "",
//     country: "",
//     countryCode: "",
//     imgId: "",
//     coordinates: { latitude: 0, longitude: 0 },
//     radius: 1,
//     zoom: 0
//   };

//   constructor(props) {
//     super(props);

//     this.state = {
//       dropdownOpen: false
//     };

//     this.handleCountryChange = this.handleCountryChange.bind(this);
//     this.onImgIdChange = this.onImgIdChange.bind(this);
//     this.dropdownToggle = this.dropdownToggle.bind(this);
//     this.onCoordsChange = this.onCoordsChange.bind(this);
//   }

//   handleCountryChange(e) {
//     const { onChange } = this.props;
//     const country = CountryCollection.find(c => c.code === e.target.value);
//     onChange({ key: "country", value: country.name });
//     onChange({
//       key: "countryCode",
//       value: country.code
//     });
//   }

//   onImgIdChange = debounce(e => {
//     this.props.onChange(e);
//   }, 500);

//   dropdownToggle() {
//     this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));
//   }

//   onCoordsChange({ key, value }) {
//     const { coordinates, onChange } = this.props;
//     let coordCopy = { ...coordinates };
//     coordCopy[key] = value;
//     onChange({ key: "coordinates", value: coordCopy });
//   }

//   render() {
//     const {
//       onChange,
//       name,
//       currency,
//       slug,
//       country,
//       countryCode,
//       imgId,
//       coordinates,
//       radius,
//       zoom
//     } = this.props;
//     const { dropdownOpen } = this.state;
//     return (
//       <div className="mt-4 mb-5">
//         <h3>General Information</h3>

//         <div className="row mt-3">
//           <div className="col-md mb-3">
//             <ImageIdCard
//               publicId={imgId}
//               onChangeTargetKey="imgId"
//               onChange={this.onImgIdChange}
//             />
//           </div>
//           <div className="col-md">
//             <InputField
//               targetKey="name"
//               value={name}
//               label="Name"
//               onChange={onChange}
//               validate={CLUBNAME_VALIDATOR}
//               options={{ required: true, type: "text" }}
//             />

//             <InputField
//               targetKey="slug"
//               value={slug}
//               label="Slug"
//               onChange={onChange}
//               validate={SLUG_VALIDATOR}
//               options={{ required: true, type: "text" }}
//             />

//             <CurrencySelect
//               value={state.currency}
//               onChange={value => setCurrency(value)}
//             />

//             <div className="row">
//               <div className="col">
//                 <InputField
//                   targetKey="latitude"
//                   value={coordinates.latitude}
//                   label="Latitude"
//                   onChange={this.onCoordsChange}
//                   validate={NUM_DECIMAL_VALIDATOR_REQ}
//                   options={{ required: true, type: "number" }}
//                 />
//               </div>

//               <div className="col">
//                 <InputField
//                   targetKey="longitude"
//                   value={coordinates.longitude}
//                   label="Longitude"
//                   onChange={this.onCoordsChange}
//                   validate={NUM_DECIMAL_VALIDATOR_REQ}
//                   options={{ required: true, type: "number" }}
//                 />
//               </div>
//             </div>

//             <div className="row">
//               <div className="col">
//                 <InputField
//                   targetKey="radius"
//                   value={radius}
//                   label="Radius (km)"
//                   onChange={onChange}
//                   validate={NUM_DECIMAL_VALIDATOR_REQ}
//                   options={{ required: true, type: "number" }}
//                 />
//               </div>

//               <div className="col">
//                 <InputField
//                   targetKey="zoom"
//                   value={zoom}
//                   label="Zoom level (0-19)"
//                   onChange={onChange}
//                   validate={INT_VALIDATOR}
//                   options={{ required: true, type: "number" }}
//                 />
//               </div>
//             </div>

//             <div className="float-input-group form-group">
//               <Dropdown
//                 id="country-dropdown"
//                 className="dropdown dropdown-fancy d-block"
//                 isOpen={dropdownOpen}
//                 toggle={this.dropdownToggle}
//               >
//                 <DropdownToggle
//                   caret
//                   className="btn btn-outline-light d-block w-100"
//                 >
//                   <span className="float-left">
//                     {countryCode && (
//                       <FlagIcon code={countryCode} className="mr-2" />
//                     )}
//                     {country}
//                   </span>
//                 </DropdownToggle>
//                 <DropdownMenu>
//                   {CountryCollection.map((country, i) => (
//                     <DropdownItem
//                       value={country.code}
//                       key={i}
//                       onClick={this.handleCountryChange}
//                     >
//                       {country.name}
//                     </DropdownItem>
//                   ))}
//                 </DropdownMenu>
//               </Dropdown>
//               <label htmlFor="country-dropdown">Country</label>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default GeneralInfo;
