// Vendor
import React from "react";
import { Query } from "react-apollo";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

// App
import Loader from "./Loader";
import { CLUBS_EXPIRING_RATES } from "../../common/Queries";

export const RatesExpiringTable = () => (
  <div className="mt-5">
    <h3>Internal Rates Expires</h3>
    <Query query={CLUBS_EXPIRING_RATES} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (loading) return <Loader fullscreen={true} />;
        if (error) return <p className="text-danger">{error.message}</p>;
        const { ratesExpirationStatus: clubs } = data;
        return (
          <Table striped borderless>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th
                  style={{
                    width: "1px",
                    whiteSpace: "nowrap"
                  }}
                >
                  EXPIRES IN
                </th>
              </tr>
            </thead>
            <tbody className="border">
              {clubs
                .sort((a, b) => a.ratedDaysLeft - b.ratedDaysLeft)
                .map((club, i) => (
                  <tr key={i}>
                    <td>
                      <Link to={`/club/${club.slug}`}>{club.name}</Link>
                    </td>
                    <td
                      className={
                        club.ratedDaysLeft < 1
                          ? "text-danger"
                          : club.ratedDaysLeft < 7
                          ? "text-warning"
                          : ""
                      }
                      style={{
                        width: "1px",
                        whiteSpace: "nowrap"
                      }}
                    >
                      {club.ratedDaysLeft < 1
                        ? "EXPIRED"
                        : `${club.ratedDaysLeft} days`}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        );
      }}
    </Query>
  </div>
);
