/**
 * Collection for all API providers
 */
const ProviderCollection = Object.freeze([
  { key: "NONE", label: "None" },
  { key: "CHRONO_GOLF", label: "Chronogolf" },
  { key: "CONCEPT", label: "Concept" },
  { key: "EZ_LINKS", label: "EZ Links" },
  { key: "GOLFSPAIN", label: "Golfspain" },
  { key: "GOLF_MANAGER", label: "Golfmanager" },
  { key: "SYS_GOLF", label: "SysGolf" }
]);

export default ProviderCollection;
