// Vendor
import React, { Component, Fragment } from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Query } from "react-apollo";

// App
import { GOLF_AREAS } from "../../common/Queries";
import Loader from "../ui/Loader";
import { TimedAlert } from "../ui/TimedAlert";

class GolfDestinationList extends Component {
  render() {
    let params = new URLSearchParams(this.props.location.search);
    return (
      <div className="mb-4">
        {params.get("deleted") && (
          <div className="d-flex w-100">
            <TimedAlert delay={3000}>Destination deleted</TimedAlert>
          </div>
        )}
        <h1>Golf destinations</h1>

        <Query query={GOLF_AREAS} fetchPolicy="cache-and-network">
          {({ loading, error, data }) => {
            if (loading) return <Loader />;
            if (error)
              return (
                <div className="text-danger">Error fetching destinations</div>
              );

            const destinationList = data.GolfAreas;

            return (
              <Table borderless striped>
                <Fragment>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Country</th>
                      <th>Clubs</th>
                      <th>Published</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {destinationList.length > 0 ? (
                      destinationList.map((dest, i) => (
                        <tr key={i}>
                          <td>{dest.name}</td>
                          <td>{dest.country}</td>
                          <td>{dest.courses.length}</td>
                          <td>
                            <FontAwesomeIcon
                              icon={dest.published ? "check-circle" : "ban"}
                              className={`text-${
                                dest.published ? "success" : "danger"
                              }`}
                            />
                          </td>
                          <td>
                            <Link to={`/golf-destination/${dest.slug}`}>
                              Edit
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-muted text-center">
                          No destinations found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Fragment>
              </Table>
            );
          }}
        </Query>

        <div className="Save-container">
          <div className="Save-container__phantom" />
          <div className="Save-container__main">
            <Link
              to="/golf-destination"
              id="btn-new"
              className="btn btn-secondary btn-lg"
            >
              Create new
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default GolfDestinationList;
