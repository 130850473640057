import {
  getSeasonStartDateFormat,
  getSeasonEndDateFormat
} from "./SeasonHelper";

export default function seasonReducer(state, action) {
  switch (action.type) {
    case "INIT":
    case "UPDATE":
      return action.payload;
    case "SET_DATES":
      return {
        ...state,
        startDate: getSeasonStartDateFormat(action.payload.startDate),
        endDate: getSeasonEndDateFormat(action.payload.endDate)
      };
    case "SET_SEASONAL_TYPE":
      return {
        ...state,
        seasonalType: action.payload
      };
    case "SET_STANDARD_RATES":
      let weekendRates = state.rates.filter(
        rate => rate.scheduleType === "WEEKEND"
      );
      let rates = weekendRates.length
        ? weekendRates.concat(action.payload)
        : action.payload;
      return {
        ...state,
        rates
      };
    case "SET_WEEKEND_RATES":
      let standardRates = state.rates.filter(
        rate => rate.scheduleType === "STANDARD"
      );
      return {
        ...state,
        rates: standardRates.concat(action.payload)
      };
    default:
      return state;
  }
}
