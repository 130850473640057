// Vendor
import React from "react";
import { Query } from "react-apollo";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";

// Common
import { COURSES_MISSING_AVAILABILITY } from "../../common/Queries";
import ProviderCollection from "../../common/ProviderCollection";

// App
import Loader from "./Loader";

export const NoAvailabilityTable = () => (
  <div className="mt-5">
    <h3>No Availability</h3>
    <Query query={COURSES_MISSING_AVAILABILITY} fetchPolicy="cache-and-network">
      {({ loading, error, data }) => {
        if (loading) return <Loader fullscreen={true} />;
        if (error) return <p className="text-danger">{error.message}</p>;

        const { coursesMissingAvailability: courses } = data;

        return (
          <Table striped borderless>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>PROVIDER</th>
              </tr>
            </thead>
            <tbody className="border">
              {courses.map((course, i) => (
                <tr key={i}>
                  <td>
                    <Link to={`/club/${course.clubSlug}`}>{course.name}</Link>
                  </td>
                  <td
                    style={{
                      width: "1px",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {
                      ProviderCollection.find(
                        p => p.key === (course.teeSheetProvider || "NONE")
                      ).label
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        );
      }}
    </Query>
  </div>
);
