import moment from "moment";
import PropTypes from "prop-types";
import React, { useContext, useState, Fragment } from "react";
import {
  Button,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Popover,
  PopoverBody
} from "reactstrap";
import { getHours, getMinutes } from "../../../../../common/TimeHelper";
import { SeasonContext } from "../Season";
import RatesHelper from "../../../../../common/RatesHelper";
import { getTimesByDayType } from "../SeasonHelper";

function SeasonDayTypeSetting(props) {
  function togglePopover() {
    setShowPopover(!showPopover);
  }

  function getRateDates(rate) {
    if (rate) return { startDate: rate.startDate, endDate: rate.endDate };

    // If rate does not exist, we fallback to season's date
    // But we use predefined times instead of 00:00 - 23:59
    const { start, end } = getTimesByDayType(dayType);
    const startDate = moment(season.startDate)
      .hour(start.hour)
      .minutes(start.minute)
      .seconds(start.second)
      .format();
    const endDate = moment(season.endDate)
      .hour(end.hour)
      .minutes(end.minute)
      .seconds(end.second)
      .format();

    return { startDate, endDate };
  }

  // hooks
  const { season } = useContext(SeasonContext);
  const [showPopover, setShowPopover] = useState(false);

  // helpers
  const { isActive, showTimeSelection, scheduleType, dayType } = props;
  const rate = RatesHelper.find(season.rates, { dayType, scheduleType });
  const { endDate, startDate } = getRateDates(rate);
  const buggyIncluded = rate ? rate.buggyIncluded === true : false;

  // id
  const checkboxActiveID = `${scheduleType}-${dayType}-checkbox-active`;
  const checkboxBuggyID = `${scheduleType}-${dayType}-checkbox-buggy`;

  // time
  let hours = getHours();
  let minutes = getMinutes();

  const TimeDropdown = ({
    value,
    minValue = -1,
    maxValue = -1,
    onClick,
    items
  }) => {
    return (
      <UncontrolledDropdown>
        <DropdownToggle size="sm" color="light" outline>
          {value}
        </DropdownToggle>
        <DropdownMenu style={{ maxHeight: 200, overflowY: "scroll" }}>
          {items.map((item, i) => (
            <DropdownItem
              key={i}
              onClick={() => onClick(item)}
              disabled={
                (minValue > -1 && parseInt(item) < minValue) ||
                (maxValue > -1 && parseInt(item) > maxValue)
              }
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const TimeDropdownGroup = ({
    label,
    date,
    field,
    className = "",
    validateDate,
    validateBefore,
    validateAfter
  }) => {
    const handleHourClick = value => {
      // set hours
      let date = mDate.hours(value);

      const sameHour = parseInt(value) === mValidateDate.hours();
      const endMinutesBeforeStart = mDate.minutes() < mValidateDate.minutes();

      // change minutes if minutes in start time is above minutes in end time
      // and the same hour is selected
      if (sameHour && endMinutesBeforeStart)
        mDate.minutes(mValidateDate.minutes());

      props.onChange({
        key: field,
        value: date.format()
      });
    };

    const mDate = moment(date);
    const mValidateDate = moment(validateDate);
    const sameHour = mDate.hours() === mValidateDate.hours();
    const hourMin = validateBefore ? mValidateDate.hours() : -1;
    const hourMax = validateAfter ? mValidateDate.hours() : -1;
    const minutesMin =
      sameHour && validateBefore ? mValidateDate.minutes() + 1 : -1;
    const minutesMax = sameHour && validateAfter ? mValidateDate.minutes() : -1;

    return (
      <div className={`mb-2 d-inline-block ${className}`}>
        <label>{label}</label>
        <InputGroup size="sm">
          <div className="input-group-prepend">
            <TimeDropdown
              value={mDate.format("HH")}
              minValue={hourMin}
              maxValue={hourMax}
              items={hours}
              onClick={handleHourClick}
            />
          </div>
          <div
            className="input-group-append input-group-prepend position-relative"
            style={{ marginLeft: -2, marginRight: -1, zIndex: 3 }}
          >
            <div className="input-group-text">:</div>
          </div>
          <div className="input-group-append">
            <TimeDropdown
              value={mDate.format("mm")}
              items={minutes}
              minValue={minutesMin}
              maxValue={minutesMax}
              onClick={value => {
                props.onChange({
                  key: field,
                  value: mDate.minutes(value).format()
                });
              }}
            />
          </div>
        </InputGroup>
      </div>
    );
  };

  return (
    <div className="SeasonDayTypeSetting mb-3">
      <label>{props.label}</label>
      <Card>
        <CardBody className="p-3">
          <div className="checkbox-group">
            <input
              id={checkboxActiveID}
              type="checkbox"
              checked={isActive}
              onChange={event => {
                const { checked } = event.target;
                const hasPrices = rate && rate.prices.length > 0;
                if (isActive && !checked && hasPrices) {
                  setShowPopover(true);
                } else {
                  setShowPopover(false);
                  props.onToggle(checked, startDate, endDate);
                }
              }}
            />
            <label htmlFor={checkboxActiveID}>
              <small>Active</small>
            </label>
          </div>
          <Popover
            placement="right"
            isOpen={showPopover}
            target={checkboxActiveID}
          >
            <PopoverBody>
              This will remove all {props.label} rate prices!
              <div className="mt-1">
                <Button
                  className="mr-1"
                  type="button"
                  outline
                  color="light"
                  size="sm"
                  onClick={() => togglePopover()}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    togglePopover();
                    props.onToggle(false);
                  }}
                >
                  OK
                </Button>
              </div>
            </PopoverBody>
          </Popover>
          {isActive && (
            <Fragment>
              {showTimeSelection && (
                <Fragment>
                  <TimeDropdownGroup
                    label="Start time"
                    date={startDate}
                    validateDate={endDate}
                    validateAfter
                    field="startDate"
                    className="mr-2"
                  />
                  <TimeDropdownGroup
                    label="End time"
                    field="endDate"
                    date={endDate}
                    validateDate={startDate}
                    validateBefore
                  />
                </Fragment>
              )}

              <div className="checkbox-group">
                <input
                  id={checkboxBuggyID}
                  type="checkbox"
                  checked={buggyIncluded}
                  onChange={event => {
                    props.onChange({
                      key: "buggyIncluded",
                      value: event.target.checked
                    });
                  }}
                />
                <label htmlFor={checkboxBuggyID}>
                  <small>Buggy included</small>
                </label>
              </div>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

SeasonDayTypeSetting.propTypes = {
  isActive: PropTypes.bool.isRequired,
  showTimeSelection: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SeasonDayTypeSetting;
