import React from "react";
import IdGeneratorHelper from "../../../../../../../common/IdGeneratorHelper";
import { useState } from "react";
import DayType from "./DayType";
import { TargetRateIdEdit } from "./TargetRateId";
import { RateNumberEdit } from "./PriceNumber";
import { DynamicPriceEdit } from "./DynamicPrice";

function EditCells({ ratePriceName, ratePrice, dayType, onChange }) {
  function getInputId(name) {
    let id = `${dayType}-${ratePriceName.replace(/\W/g, "")}-${name}`;
    return id.toLowerCase();
  }

  // State
  const [targetRateId, setTargetRateId] = useState("");
  const [price, setPrice] = useState("");
  const [rackPrice, setRackPrice] = useState("");
  const [multiplier, setMultiplier] = useState("");
  const [dynamic, setDynamic] = useState(false);

  const dynamicPriceId = IdGeneratorHelper.getRandomId();

  React.useEffect(() => {
    if (ratePrice) {
      setTargetRateId(ratePrice.targetRateId);
      setPrice(ratePrice.price);
      setRackPrice(ratePrice.rackPrice);
      setMultiplier(ratePrice.multiplier);
      setDynamic(ratePrice.useTeeSheetProviderPricing || false);
    }
  }, [ratePrice]);

  return (
    <>
      <td className="text-left" style={{ whiteSpace: "nowrap" }}>
        <p className="m-0">
          <DayType dayType={dayType} />
        </p>
      </td>
      <td style={{ whiteSpace: "nowrap" }}>
        <div className="d-block">
          <TargetRateIdEdit
            name="targer-rate-id"
            targetRateId={targetRateId}
            onChange={value => {
              setTargetRateId(value);
              onChange({
                key: "targetRateId",
                value
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("price")}
            name="price"
            value={price}
            onChange={value => {
              const price = parseFloat(value);
              setPrice(price);
              onChange({
                key: "price",
                value: price
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("rackprice")}
            name="rack-price"
            value={rackPrice}
            validateValue={price}
            onChange={value => {
              const rackPrice = parseFloat(value);
              setRackPrice(rackPrice);
              onChange({
                key: "rackPrice",
                value: rackPrice
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <RateNumberEdit
            id={getInputId("multiplier")}
            name="multiplier"
            value={multiplier}
            validateValue={price}
            onChange={value => {
              const multiplier = parseInt(value);
              setMultiplier(multiplier);
              onChange({
                key: "multiplier",
                value: multiplier
              });
            }}
          />
        </div>
      </td>
      <td>
        <div className="d-block">
          <DynamicPriceEdit
            name={dynamicPriceId}
            value={dynamic}
            onChange={value => {
              setDynamic(value);
              onChange({
                key: "useTeeSheetProviderPricing",
                value
              });
            }}
          />
        </div>
      </td>
    </>
  );
}

export default EditCells;
