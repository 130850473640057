// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { DateRangePicker, isInclusivelyAfterDay } from "react-dates";
import momentPropTypes from "react-moment-proptypes";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

function DateRangeFacet(props) {
  const [focusedInput, setFocus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // only load dates from props initially
  useEffect(() => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DateRangePicker
      startDatePlaceholderText={props.startDatePlaceholderText}
      endDatePlaceholderText={props.endDatePlaceholderText}
      startDate={startDate} // momentPropTypes.momentObj or null,
      startDateId={props.startDateId}
      endDate={endDate} // momentPropTypes.momentObj or null,
      endDateId={props.endDateId}
      onClose={({ startDate, endDate }) => {
        if (startDate && endDate && startDate.isAfter(endDate)) {
          setEndDate(startDate);
        }
      }}
      onDatesChange={({ startDate, endDate }) => {
        if (props.showClearDates) {
          setStartDate(startDate);
          setEndDate(endDate);
          props.onDatesChange({ startDate, endDate });
        } else {
          if (startDate) setStartDate(startDate);
          if (endDate) setEndDate(endDate);

          // only invoke handler if both dates have changed
          if (startDate && endDate) {
            props.onDatesChange({
              startDate,
              endDate
            });
          }
        }
      }} // PropTypes.func.isRequired,
      focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
      onFocusChange={setFocus} // PropTypes.func.isRequired,
      hideKeyboardShortcutsPanel={true}
      small={props.small}
      customInputIcon={
        props.showCalendarIcon ? (
          <FontAwesomeIcon icon={["far", "calendar-alt"]} />
        ) : null
      }
      enableOutsideDays={props.enableDatesOutsideRange}
      isOutsideRange={props.isOutsideRange}
      showClearDates={props.showClearDates}
      displayFormat={props.displayFormat}
    />
  );
}

DateRangeFacet.propTypes = {
  endDate: momentPropTypes.momentObj,
  endDateId: PropTypes.string.isRequired,
  enableDatesOutsideRange: PropTypes.bool,
  onDatesChange: PropTypes.func.isRequired,
  isOutsideRange: PropTypes.func.isRequired,
  showCalendarIcon: PropTypes.bool,
  small: PropTypes.bool,
  startDate: momentPropTypes.momentObj,
  startDateId: PropTypes.string.isRequired,
  showClearDates: PropTypes.bool,
  startDatePlaceholderText: PropTypes.string,
  endDatePlaceholderText: PropTypes.string,
  displayFormat: PropTypes.string
};

DateRangeFacet.defaultProps = {
  enableDatesOutsideRange: false,
  isOutsideRange: day => !isInclusivelyAfterDay(day, moment()),
  showCalendarIcon: false,
  small: false,
  showClearDates: false,
  startDatePlaceholderText: "Start Date",
  endDatePlaceholderText: "End Date",
  displayFormat: "YYYY-MM-DD"
};

export default DateRangeFacet;
