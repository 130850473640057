import qs from "qs";

class URLHelper {
  static getParam = (location, param) => {
    const urlParams = URLHelper.queryToState(location);
    return urlParams[param];
  };

  static queryToState = location => qs.parse(location.search.slice(1));

  static queryFromState = state => `?${qs.stringify(state)}`;

  static isLocalhost() {
    return (
      window.location.origin.match(
        /^(http)+s?(:\/\/localhost)+|(http)+s?(:\/\/127.0.0.1)+/g
      ) !== null
    );
  }
}

export default URLHelper;
