import gql from "graphql-tag";
import {
  START_PAGE_FIELDS,
  GOLF_AREA_FIELDS,
  CLUB_ATTRIBUTES
} from "./GraphQLFields";

// ORDERS
export const ORDER_BASE_ATTRIBUTES = `
  _id
  firstName
  lastName
  email
  total
  orderReference
  createdAt
`;

export const ORDER_TEETIMES = `
  teetimes {
    _id
    courseId
    courseName
    startingTee
    buggies
    buggyIncluded
    buggyDisabled
    players
    playDate
    cancelled
    cancelledDate
    cancelDateTime
    currency
    price
    bookingDetailsIds
    teeSheetOrderReference
    teeSheetCourseId
    bookingErrors {
      reason
    }
    ratesUsed {
      price
      rackPrice
      rateType
      targetRateId
      players
    }
  }
`;

export const ORDER_CLUBS = `
  clubs {
    total
    clubId
    name
    ${ORDER_TEETIMES}
  }
`;

// CLUBS
export const GET_CLUB_BY_ID_QUERY = gql`
  query GetClubById($id: ID!) {
    getClubById(_id: $id) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const CLUB_GET_BY_SLUG = gql`
  query GetClubBySlug($slug: String) {
    getClubBySlug(slug: $slug) {
      ${CLUB_ATTRIBUTES}
    }
  }
`;

export const CLUB_SEARCH_PAGE = gql`
  query ClubSearchPage(
    $index: Int!
    $sorting: ClubSortInput!
    $filter: ClubFilterOptionInput
  ) {
    clubSearchPage(pageIndex: $index, sorting: $sorting, filter: $filter) {
      _id
      name
      city
      country
      claimed
      slug
      updatedAt
    }
  }
`;

export const CLUB_SEARCH = gql`
  query ClubSearch($filter: ClubFilterOptionInput) {
    clubSearch(filter: $filter) {
      matchCount
      pageCount
    }
  }
`;

export const CLUBS_EXPIRING_RATES = gql`
  query RatesExpirationStatus($days: Int) {
    ratesExpirationStatus(days: $days) {
      _id
      name
      slug
      ratedDaysLeft
    }
  }
`;

export const LINKABLE_COURSES = gql`
  query getActiveCourses {
    getActiveCourses {
      _id
      name
      description
      i18n
    }
  }
`;

export const COURSES_MISSING_AVAILABILITY = gql`
  query coursesMissingAvailability {
    coursesMissingAvailability {
      name
      clubSlug
      teeSheetProvider
    }
  }
`;

// GOLF AREAS
export const GOLF_AREAS = gql`
  query GolfAreas($publishStatus: PublishStatus) {
    GolfAreas(publishStatus: $publishStatus) {
      _id
      name
      slug
      country
      countryCode
      published
      courses {
        _id
      }
    }
  }
`;

export const GOLF_AREA_BY_SLUG = gql`
  query GolfArea($slug: String!) {
    GolfArea(slug: $slug) {
      ${GOLF_AREA_FIELDS}
    }
  }
`;

// START PAGE
export const START_PAGE = gql`
  query StartPage {
    startPage {
      ${START_PAGE_FIELDS}
    }
  }
`;

export const IMAGES_BY_TAG = gql`
  query ImagesByTag($tag: String!) {
    ImagesByTag(tag: $tag) {
      publicId
      width
      height
      format
      bytes
    }
  }
`;

// ACCOUNT MANAGERS
export const ACCOUNT_MANAGERS = gql`
  query AccountManagers {
    accountManagers {
      _id
      firstName
      lastName
      #email
      #updatedAt
    }
  }
`;

// CURRENCIES
export const AVAILABLE_CURRENCIES = gql`
  query AvailableCurrencies {
    availableCurrencies
    ## {
    #  _id
    #  firstName
    #  lastName
    #  #email
    #updatedAt
    # }
  }
`;

// RATES
export const MISSING_RATES = gql`
  query missingRates($muted: Boolean) {
    missingRates(muted: $muted) {
      _id
      name
      rateTypeId
      muted
      clubId {
        name
        slug
        teeSheetProvider
      }
      rateEndDate
    }
  }
`;

// ORDERS

export const ORDER_SEARCH = gql`
  query orderSearch($filter: OrderSearchFilter) {
    orderSearch(filter: $filter) {
      matchCount
      pageCount
    }
  }
`;

export const ORDER_LIST = gql`
  query orderList($pageIndex: Int!, $filter: OrderSearchFilter) {
    orderList(pageIndex: $pageIndex, filter: $filter) {
      ${ORDER_BASE_ATTRIBUTES}
      clubs {
        teetimes {
          courseName
          playDate
          currency
        }
      }
    }
  }
`;

export const ORDER = gql`
  query order($id: ID!) {
    order(id: $id) {
      ${ORDER_BASE_ATTRIBUTES}
      ${ORDER_CLUBS}
    }
  }
`;
