import React from "react";

export default function usePreventWindowUnload(preventDefault) {
  function handleBeforeUnload(event) {
    // Cancel the event as stated by the standard.
    event.preventDefault();
    // Chrome requires returnValue to be set.
    event.returnValue = "";
  }

  React.useEffect(() => {
    // return event as normal if no prevention
    if (!preventDefault) return;
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [preventDefault]);
}
