// Vendor
import React, { PureComponent } from "react";
import { Image } from "cloudinary-react";
import { Card, Label } from "reactstrap";
import { AvGroup, AvInput, AvFeedback } from "availity-reactstrap-validation";
import PropTypes from "prop-types";

// App
import CloudinaryHelper from "../../common/CloudinaryHelper";
import { CLOUDINARY_PATH_VALIDATOR } from "../../common/Validators";
import debounce from "../../common/debounce";

export default class ImageIdCard extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onChangeTargetKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    required: PropTypes.bool,
    publicId: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    crop: PropTypes.string,
    fallback: PropTypes.string
  };
  static defaultProps = {
    required: false,
    publicId: "",
    fallback: CloudinaryHelper.FALLBACK_IMG
  };

  constructor(props) {
    super(props);

    this.onImgIdChange = this.onImgIdChange.bind(this);
  }

  onImgIdChange = debounce(value => {
    const { onChange, onChangeTargetKey } = this.props;
    onChange({ key: onChangeTargetKey, value });
  }, 500);

  render() {
    const publicId = this.props.publicId || "";
    const { className, onChangeTargetKey, fallback, required } = this.props;

    return (
      <Card className={className} style={{ backgroundColor: "#dadada" }}>
        <Image
          className="w-100 border border-bottom-0"
          publicId={publicId || fallback}
          responsive
        />
        <AvGroup className="float-input-group mb-0">
          <Label for={`input-${onChangeTargetKey}`} hidden={publicId === ""}>
            Cloudinary public id
          </Label>
          <AvInput
            className="InputField rounded-0"
            name={`input-${onChangeTargetKey}`}
            id={`input-${onChangeTargetKey}`}
            value={publicId}
            placeholder="Cloudinary public id"
            validate={CLOUDINARY_PATH_VALIDATOR}
            required={required}
            onChange={e => this.onImgIdChange(e.target.value)}
          />
          <AvFeedback style={{ position: "absolute" }}>
            Invalid input
          </AvFeedback>
        </AvGroup>
      </Card>
    );
  }
}
