// vendor
import React from "react";
import { useQuery } from "react-apollo";

// app
import { AVAILABLE_CURRENCIES } from "../../common/Queries";
import Loader from "./Loader";
import { Select } from "./FunctionalComponents";
import variables from "../../styles/bootstrap/_variables.scss";
function CurrencySelect(props) {
  const { loading, error, data } = useQuery(AVAILABLE_CURRENCIES);

  if (loading) return <Loader />;
  if (error) return <p className="text-danger">Could not load currencies.</p>;

  return (
    <Select
      label="Currency"
      id="club-currency"
      value={props.value}
      onChange={e => {
        props.onChange(e.target.value);
      }}
      style={props.value === null ? { color: variables.gray400 } : null}
    >
      {!props.value && (
        <option disabled value="">
          Select currency
        </option>
      )}
      {data.availableCurrencies.map(currency => (
        <option value={currency} key={currency}>
          {currency}
        </option>
      ))}
    </Select>
  );
}
export default CurrencySelect;
