// Vendor
import PropTypes from "prop-types";
import React, { useEffect, useReducer, useState } from "react";
import { Table, Button } from "reactstrap";

// App
import FlagIcon from "../../ui/FlagIcon";
import reducer from "./dynamicTransTableReducer";
import DynamicTransTableRow from "./DynamicTransRow";
import { DynamicTransTableRowPropType } from "./DynamicTransRow";

export const DynamicTransTableContext = React.createContext(null);

function DynamicTransTable(props) {
  const initialState = { items: [], trigger: false };
  const [hasNewTrans, setHasNewTrans] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { items } = state;

  // Initialize every time props.items change
  useEffect(() => {
    dispatch({ type: "INIT", payload: props.items, trigger: false });
  }, [props.items]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    // Only trigger change on-demand
    if (state.trigger) {
      if (hasNewTrans) setHasNewTrans(false);
      props.onChange(items);
    }
  }, [items]); // eslint-disable-line react-hooks/exhaustive-deps

  // join english with translated languages
  const languages = state.items.length
    ? ["en-GB"].concat(Object.keys(state.items[0].i18n))
    : [];

  function addTerm() {
    const { translationField, additionalFields } = props;
    const obj = { [translationField.key]: "" };

    let item = {
      [translationField.key]: "",
      i18n: {
        "es-ES": { ...obj },
        "sv-SE": { ...obj },
        "de-DE": { ...obj },
        "da-DK": { ...obj },
        "fi-FI": { ...obj },
        "fr-FR": { ...obj },
        "it-IT": { ...obj },
        "nl-NL": { ...obj },
        "nb-NO": { ...obj },
        "ru-RU": { ...obj }
      }
    };

    // add fields to empty object
    if (additionalFields.length > 0) {
      additionalFields.forEach(field => {
        item[field.key] = "";
      });
    }

    setHasNewTrans(true);

    dispatch({ type: "ADD", payload: item });
  }

  const { additionalFields, translationField } = props;

  return (
    <DynamicTransTableContext.Provider
      value={{
        dispatch,
        languages,
        translationField: translationField.key,
        additionalFields
      }}
    >
      {items.length > 0 && (
        <Table responsive striped borderless>
          <thead>
            <tr>
              {additionalFields.length > 0 &&
                additionalFields.map((field, i) => (
                  <th key={i}>{field.header}</th>
                ))}
              {languages.map((lang, i) => (
                <th key={i} style={{ whiteSpace: "nowrap" }}>
                  <FlagIcon
                    code={lang.substr(3).toLowerCase()}
                    className="mx-1"
                  />
                  {lang.substr(0, 2).toUpperCase()}
                </th>
              ))}
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody className="border">
            {state.items.map((item, i) => (
              <DynamicTransTableRow
                key={i}
                item={item}
                index={i}
                isNew={hasNewTrans && i === state.items.length - 1}
              />
            ))}
          </tbody>
        </Table>
      )}
      <Button color="link" onClick={addTerm}>
        + Add
      </Button>
    </DynamicTransTableContext.Provider>
  );
}

DynamicTransTable.propTypes = {
  onChange: PropTypes.func.isRequired,
  translationField: PropTypes.shape({
    header: PropTypes.string,
    key: PropTypes.string.isRequired
  }).isRequired,
  additionalFields: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      key: PropTypes.string.isRequired
    })
  ),
  items: PropTypes.arrayOf(DynamicTransTableRowPropType)
};

DynamicTransTable.defaultProps = {
  additionalFields: [],
  items: []
};

export default DynamicTransTable;
