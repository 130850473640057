import moment from "moment";

export function getTimesByDayType(dayType) {
  switch (dayType) {
    case "EARLY_BIRD":
      return {
        start: {
          hour: 0,
          minute: 0,
          second: 0
        },
        end: {
          hour: 9,
          minute: 59,
          second: 59
        }
      };
    case "TWILIGHT":
      return {
        start: {
          hour: 15,
          minute: 0,
          second: 0
        },
        end: {
          hour: 23,
          minute: 59,
          second: 59
        }
      };
    default:
      return {
        start: {
          hour: 0,
          minute: 0,
          second: 0
        },
        end: {
          hour: 23,
          minute: 59,
          second: 59
        }
      };
  }
}

export function changeRateDate(oldDate, newDate) {
  const old = moment(oldDate);
  return moment(newDate)
    .hours(old.hours())
    .minutes(old.minutes())
    .seconds(old.seconds())
    .format("YYYY-MM-DD HH:mm:ss");
}

export function getSeasonEndDateFormat(date) {
  return moment(date)
    .hours(23)
    .minute(59)
    .seconds(59)
    .format();
}

export function getSeasonStartDateFormat(date) {
  return moment(date)
    .hours(0)
    .minute(0)
    .seconds(0)
    .format();
}
