// Vendor
import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

// App
import Category from "../Category";

function CategoryList({ categories, onChange }) {
  const [tabIndex, setTabIndex] = useState(0);
  const isLastTab = tabIndex === categories.length - 1;

  function handleNewCategory() {
    const defaultValues = { title: "", description: "" };

    onChange({
      key: "categories",
      value: [
        ...categories,
        {
          imgId: "",
          title: "",
          description: "",
          i18n: {
            "es-ES": { ...defaultValues },
            "sv-SE": { ...defaultValues },
            "de-DE": { ...defaultValues },
            "da-DK": { ...defaultValues },
            "fi-FI": { ...defaultValues },
            "fr-FR": { ...defaultValues },
            "it-IT": { ...defaultValues },
            "nl-NL": { ...defaultValues },
            "nb-NO": { ...defaultValues },
            "ru-RU": { ...defaultValues }
          }
        }
      ]
    });
    setTabIndex(categories.length);
  }

  function handleCategoryChange(category) {
    let arrayClone = [...categories];
    arrayClone[tabIndex] = category;

    onChange({
      key: "categories",
      value: arrayClone
    });
  }

  function handleCategoryDelete() {
    let filteredCopy = categories.filter((_, i) => i !== tabIndex);

    onChange({
      key: "categories",
      value: filteredCopy
    });

    if (tabIndex !== 0 && isLastTab) setTabIndex(tabIndex - 1);
  }

  return (
    <div className="mb-5">
      <h3>Categories</h3>

      <Nav tabs>
        {categories.map((item, i) => (
          <NavItem key={i}>
            <NavLink
              active={i === tabIndex}
              onClick={() => setTabIndex(i)}
              className={`${item.title ? "" : "font-italic"}`}
            >
              {item.title || "Untitled"}
            </NavLink>
          </NavItem>
        ))}
        <NavItem>
          <NavLink className="text-primary" onClick={handleNewCategory}>
            +New category
          </NavLink>
        </NavItem>
      </Nav>

      {categories.length > 0 && (
        <TabContent>
          <TabPane>
            <Category
              onChange={handleCategoryChange}
              onDelete={handleCategoryDelete}
              category={categories[tabIndex]}
            />
          </TabPane>
        </TabContent>
      )}
    </div>
  );
}

export default CategoryList;
