// Vendor
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "react-apollo";
import { useHistory } from "react-router-dom";

// Common
import { ORDER_LIST, ORDER_SEARCH } from "../../common/Queries";
import URLHelper from "../../common/URLHelper";

// App
import CondensedNavButtonBar from "../ui/CondensedNavButtonBar";
import OrderSearch from "./OrderSearch";
import OrderListTable from "./OrderListTable";

function OrderList() {
  const history = useHistory();
  const urlQueryParams = URLHelper.queryToState(history.location);
  const {
    page,
    userText,
    orderText,
    clubName,
    createdFrom,
    createdTo,
    teetimeFrom,
    teetimeTo
  } = urlQueryParams;

  // Hooks
  const [pageCount, setPageCount] = useState(1);
  const [matchCount, setMatchCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [getOrderList, { loading: orderListLoading, data }] = useLazyQuery(
    ORDER_LIST
  );
  const [getPageCount, { loading: pageCountLoading }] = useLazyQuery(
    ORDER_SEARCH,
    {
      onCompleted({ orderSearch }) {
        setPageCount(orderSearch.pageCount);
        setMatchCount(orderSearch.matchCount);
      }
    }
  );

  // Effect hooks
  useEffect(() => {
    // Get page count on changed search params
    getPageCount({
      variables: {
        filter: buildFilter()
      }
    });

    // Ensure page index 0
    if (pageIndex === 0) {
      fetchOrders();
    } else pageBtnClickHandler(0);
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    userText,
    orderText,
    clubName,
    createdFrom,
    createdTo,
    teetimeFrom,
    teetimeTo
  ]);

  useEffect(() => {
    // Update page index from url if changed
    if (page) setPageIndex(Number(page) - 1);
  }, [page]);

  useEffect(() => {
    // Fetch new list on pageIndex change
    fetchOrders();
  }, [pageIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  // Handlers
  function fetchOrders() {
    getOrderList({
      variables: {
        pageIndex,
        filter: buildFilter()
      }
    });
  }

  function pageBtnClickHandler(newIndex) {
    urlQueryParams.page = Number(newIndex) + 1;
    history.push({
      search: URLHelper.queryFromState(urlQueryParams)
    });
  }

  function buildFilter() {
    let filter = {
      userSearchText: userText,
      orderSearchText: orderText,
      clubName
    };
    if (createdFrom) {
      filter.createdDate = {
        start: createdFrom,
        end: createdTo
      };
    }
    if (teetimeFrom) {
      filter.playDate = {
        start: teetimeFrom,
        end: teetimeTo
      };
    }
    return filter;
  }

  const orderList = data ? data.orderList : null;

  return (
    <div className="page">
      <h1 className="mb-4">Orders</h1>

      <OrderSearch />

      <div className="d-inline-block mr-2">Pages: {pageCount}</div>
      <div className="d-inline-block">Matches: {matchCount}</div>

      <OrderListTable
        orderList={orderList}
        loading={pageCountLoading || orderListLoading || !data}
      />

      <CondensedNavButtonBar
        value={pageIndex}
        onBtnClick={pageBtnClickHandler}
        pageCount={pageCount}
      />
    </div>
  );
}

export default OrderList;
