// Vendor
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { UncontrolledTooltip, Nav, NavItem, NavLink } from "reactstrap";
import * as Sentry from "@sentry/browser";

// App
import Routes from "./Routes";
import Auth from "../../common/Auth";
import LoginPage from "../users/LoginPage";
import { Alert } from "reactstrap";
import ErrorBoundary from "../ui/ErrorBoundary";

export const LayoutContext = React.createContext(null);

function Layout(props) {
  function addAlert(alert) {
    setAlert(alert);
    animate();
  }

  const [transform, setTransform] = useState("translateY(-100%)");

  async function animate() {
    await timeout(500, () => setTransform("translateY(0%)"));
    await timeout(3500, () => setTransform("translateY(-100%)"));
    timeout(1000, () => setAlert(null));
  }

  function timeout(ms, fn) {
    return new Promise(resolve => {
      setTimeout(() => {
        fn();
        resolve(true);
      }, ms);
    });
  }

  const [alert, setAlert] = useState(null);
  const [authenticated, setAuthenticated] = useState(Auth.isAuthenticated());
  const items = [
    {
      id: "dashboard",
      icon: "tachometer-alt",
      label: "Dashboard",
      route: "/"
    },
    {
      id: "start-page",
      icon: "home",
      label: "Startpage",
      route: "/start-page"
    },
    {
      id: "orders",
      icon: "shopping-basket",
      label: "Orders",
      route: "/orders"
    },
    { id: "clubs", icon: "golf-ball", label: "Clubs", route: "/clubs" },
    {
      id: "golf-destinations",
      icon: "map",
      label: "Golf destinations",
      route: "/golf-destinations"
    },
    { id: "users", icon: "users", label: "Users", route: "/users" },
    {
      id: "invoices",
      icon: "file-invoice",
      label: "Invoices",
      route: "/invoices"
    }
    // { id: "prices", icon: "euro-sign", label: "Prices", route: "/prices" },
    // { id: "availability", icon: "calendar-alt", label: "Availability", route: "/availability" },
    // { id: "logs", icon: "sticky-note", label: "Logs", route: "/logs" },
  ];

  useEffect(() => {
    Sentry.configureScope(scope => scope.setUser(Auth.getSentryUserContext()));
  }, [authenticated]);

  const isActiveRoute = route => route === props.location.pathname;
  const StartPageIcon = () => (
    <span className="fa-layers fa-fw">
      <FontAwesomeIcon icon="tv" size="lg" transform="left-2" />
      <FontAwesomeIcon icon="pencil-alt" size="lg" transform="shrink-8 up-2" />
    </span>
  );

  return (
    <LayoutContext.Provider value={{ addAlert }}>
      {alert && (
        <Alert
          color={alert.color}
          className="position-fixed w-100"
          style={{
            zIndex: 1021, // above sticky header
            top: 0,
            left: 0,
            transition: "transform .25s linear",
            transform
          }}
        >
          {alert.message}
        </Alert>
      )}
      <div className="Layout d-flex flex-column flex-sm-row align-items-sm-start position-relative">
        <Nav
          className="navbar-nav sticky-top flex-row flex-sm-column justify-content-center text-center p-2 mr-sm-5"
          pills
          style={{ top: 0 }}
        >
          {authenticated
            ? items.map((item, index) => (
                <NavItem key={index}>
                  <NavLink
                    className="p-3 my-1"
                    href={item.route}
                    id={item.id}
                    active={isActiveRoute(item.route)}
                    style={{ width: 56, height: 56, borderRadius: "2rem" }}
                  >
                    {item.id === "start-page" ? (
                      <StartPageIcon />
                    ) : (
                      <FontAwesomeIcon icon={item.icon} size="lg" />
                    )}
                  </NavLink>
                  <UncontrolledTooltip
                    delay={{ show: 0, hide: 0 }}
                    placement="auto"
                    target={item.id}
                  >
                    {item.label}
                  </UncontrolledTooltip>
                </NavItem>
              ))
            : null}
          <NavItem hidden={!authenticated}>
            <NavLink
              className="p-3 my-1"
              href="#"
              onClick={() => {
                Auth.logout();
                setAuthenticated(false);
              }}
              id="logout"
            >
              <FontAwesomeIcon icon="sign-out-alt" size="lg" />
            </NavLink>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="right"
              target="logout"
            >
              Log out
            </UncontrolledTooltip>
          </NavItem>
          <NavItem hidden={authenticated}>
            <NavLink className="p-3 my-1" href="/" id="login">
              <FontAwesomeIcon icon="sign-in-alt" size="lg" />
            </NavLink>
            <UncontrolledTooltip
              delay={{ show: 0, hide: 0 }}
              placement="right"
              target="login"
            >
              Log in
            </UncontrolledTooltip>
          </NavItem>
        </Nav>
        <div className="container mt-4">
          {authenticated ? (
            <ErrorBoundary context="routes" showErrorReportBtn showReloadBtn>
              <Routes />
            </ErrorBoundary>
          ) : (
            <LoginPage onLogin={() => setAuthenticated(true)} />
          )}
        </div>
      </div>
    </LayoutContext.Provider>
  );
}

export default withRouter(Layout);
