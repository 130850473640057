export default function startPageReducer(state, action) {
  switch (action.type) {
    case "INIT":
      return action.payload;
    case "HEADER_IMAGE_SLUG":
      return {
        ...state,
        headerImageSlug: action.payload
      };
    case "GOLF_COURSES":
      return {
        ...state,
        courses: action.payload
      };
    case "GOLF_DESTINATIONS":
      return {
        ...state,
        golfDestinations: action.payload
      };
    case "GOLF_DESTINATION_PILLS":
      return {
        ...state,
        golfDestinationPills: action.payload
      };
    case "TESTIMONIALS":
      return {
        ...state,
        testimonials: action.payload
      };
    default:
      return state;
  }
}
