export const START_PAGE_FIELDS = `
  headerImageSlug
  courses{
    _id
    name
  }
  golfDestinations{
    _id
    name
  }
  golfDestinationPills{
    text
    param
    paramValue
    i18n
  }
  testimonials{
    name
    testimonial
    i18n
  }
`;

export const GOLF_AREA_FIELDS = `
  _id
  name
  slug
  coordinates {
    latitude
    longitude
  }
  radius
  zoom
  country
  countryCode
  currency
  imgId
  keyFigures {
    courses
    sunnyDays
    taxiPrice
    beerPrice
  }
  published
  description
  summary
  i18n
  categories {
    imgId
    title
    description
    i18n
  }
  fullCoursesObjects {
    _id
    name
    description
    i18n
  }
  cities {
    name
    imgId
  }
`;

// partials

export const SEASON_RATE_ATTRIBUTES = `
  _id
  seasonalType
  startDate
  endDate
  rates{
    scheduleType
    dayType
    startDate
    endDate
    prices{
      price
      rackPrice
      rateType
      multiplier
      targetRateId
      useTeeSheetProviderPricing
    }
    buggyIncluded
  }
`;

export const COURSE_ATTRIBUTES = `
  _id
  slug
  teeSheetCourseId
  name
  holes
  par
  tees{
    name
    targetId
  }
  cancellationHours
  amenities
  dailyOrderEmail
  address
  city
  state
  country
  countryCode
  phone
  membership
  courseType
  architect
  currency
  active
  description
  i18n
  areas
  placeId
  localTimeZone
  loc {
    type
    coordinates
  }
  seasonRates {
    ${SEASON_RATE_ATTRIBUTES}
  }
`;

export const CLUB_ATTRIBUTES = `
  _id
  name
  slug
  dailyOrderEmail
  address
  city
  state
  country
  countryCode
  phone
  website
  teeSheetClubId
  teeSheetProvider
  claimed
  #claimedDate
  accountManager{
    _id
    firstName
    lastName
  }
  contacts {
    name
    title
    email
  }
  courses {
    ${COURSE_ATTRIBUTES}
  }
  #updatedAt
  #deleted
  #deletedAt
`;
